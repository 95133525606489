import { UseComboboxGetItemPropsOptions } from 'downshift';
import Box from '@ui-v2/core/Box/Box';
import Text from '@ui-v2/core/Text/Text';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { SearchState, StationListNode } from '@web/context/SearchWidgetContext';
import Notification from '@web/core/Notification/Notification';
import { getItemLevel } from '../helpers';
import StationPickerItem from '../StationPickerItem';

type Props = {
  getItemProps: (
    options: UseComboboxGetItemPropsOptions<StationListNode>,
  ) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
  highlightedIndex: number;
  isShowingCTAMenu: boolean;
  isShowingNoResultsMessage: boolean;
  isShowingResults: boolean;
  items: StationListNode[];
  pickerType: SearchState;
};

const StationPickerResults = ({
  getItemProps,
  highlightedIndex,
  isShowingCTAMenu,
  isShowingNoResultsMessage,
  isShowingResults,
  items,
  pickerType,
}: Props) => {
  const { t } = useCmsTranslation();

  return (
    <div>
      {isShowingResults && (
        <Box>
          {items.map((item, index) => {
            const isHighlighted = highlightedIndex === index;
            const options = getItemProps({ item, index });

            return (
              <StationPickerItem
                {...options}
                data-cy="autocomplete-item"
                isHighlighted={isHighlighted}
                item={item}
                key={options.id}
                level={getItemLevel(item, items)}
                pickerType={pickerType}
              />
            );
          })}
        </Box>
      )}
      {isShowingNoResultsMessage && (
        <Box my={16} px={16}>
          <Notification size="small" variant="warning">
            <Text as="div" variant="body-2">
              {t('search_widget_no_result', "Sorry, we don't offer this route")}
            </Text>
          </Notification>
        </Box>
      )}
      {isShowingCTAMenu && (
        <Box>
          {items.length > 0 && (
            <Box mb={8} mt={16} px={16}>
              <Text as="div" variant="heading-6">
                {t('recent_searches', 'Recent searches')}
              </Text>
            </Box>
          )}
          {items.map((item, index) => {
            const isHighlighted = highlightedIndex === index;
            const options = getItemProps({ item, index });

            return (
              <StationPickerItem
                {...options}
                isHighlighted={isHighlighted}
                item={item}
                key={options.id}
                pickerType={pickerType}
              />
            );
          })}
        </Box>
      )}
      {isShowingCTAMenu && (
        <Box bg="surface.secondary" p={16}>
          <Text as="div" variant="heading-6">
            {pickerType === 'selecting-take-off-station' &&
              t('search_widget_cta_title', 'Choose your origin')}
            {pickerType === 'selecting-destination-station' &&
              t(
                'search_widget_cta_title_destination',
                'Choose your destination',
              )}
          </Text>
          <Text as="div" variant="body-2">
            {t(
              'search_widget_cta_description',
              'Start typing to search for locations',
            )}
          </Text>
        </Box>
      )}
    </div>
  );
};

export default StationPickerResults;
